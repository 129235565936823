import React from 'react';
import { graphql } from 'gatsby';

import { Typography } from '@material-ui/core';

import Layout from '../components/Layout';
import Link from '../components/Link';

import { groupBy } from '../helpers/commonHelpers';

const Stations = ({
  data: { wrapper: { nodes } },
}) => {
  const flatNodes = nodes.map(
    ({ properties: { label, code, sub_management_unit: { label: subManagementUnitLabel } } }) => ({
      label,
      code,
      subManagementUnitLabel,
    }),
  );

  // we group with js to keep sorted order from the graphql query
  // if we use the group option of graph ql query, orders are not preserved
  const groupBySsUg = groupBy('subManagementUnitLabel');
  const groupedStations = Object.entries(
    groupBySsUg(flatNodes),
  ).map(([key, value]) => ({ subManagementUnitLabel: key, stations: value }));

  return (
    <Layout title="Liste des stations" noFooter>
      <Typography variant="body2" gutterBottom>
        <Link to="/">‹ Retour à la carte</Link>
      </Typography>

      <Typography variant="h1" gutterBottom>
        Toutes les stations
      </Typography>

      {groupedStations.map(({ subManagementUnitLabel, stations }) => (
        <React.Fragment key={subManagementUnitLabel}>
          <Typography variant="h2">{subManagementUnitLabel}</Typography>
          <ul>
            {stations.map(({ code, label }) => (
              <li key={code}>
                <Link to={`/station/${code}`}>
                  {label}
                </Link>
              </li>
            ))}
          </ul>
        </React.Fragment>
      ))}
    </Layout>
  );
};

export default Stations;

// sort on properties___sub_management_unit___id to be replaced by ss ug order when available
export const pageQuery = graphql`
  {
    wrapper: allGeojsonFeature(
      filter: {
        layer: {eq: "stations"}
      },
      sort: {
        order: [ASC, ASC],
        fields: [properties___sub_management_unit___order, properties___order]
      }
    ) {
      nodes {
        properties {
          label
          code
          sub_management_unit {
            label
          }
        }
      }
    }
  }
`;
